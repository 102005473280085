import React, { createContext, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

interface SettingsContextValue {
  users: string[];
  extraDeciders: string[];
  statements: string[];
  actions: string[];
}
const SettingsContext = createContext<SettingsContextValue | null>(null);

export const SettingsContextProvider: React.FC = ({ children }) => {
  const data = useStaticQuery(graphql`
    query DataQuery {
      allPrismicPlayer(filter: { data: { active: { eq: true } } }) {
        edges {
          node {
            data {
              name {
                text
              }
            }
          }
        }
      }
      allPrismicAction(filter: { data: { active: { eq: true } } }) {
        edges {
          node {
            data {
              action {
                text
              }
            }
          }
        }
      }
      allPrismicStatement(filter: { data: { active: { eq: true } } }) {
        edges {
          node {
            data {
              statement {
                text
              }
            }
          }
        }
      }
    }
  `);

  const users = data.allPrismicPlayer.edges.map((edge) => edge.node.data.name.text);
  const statements = data.allPrismicStatement.edges.map((edge) => edge.node.data.statement.text);
  const actions = data.allPrismicAction.edges.map((edge) => edge.node.data.action.text);


  const settings: SettingsContextValue = {
    users,
    extraDeciders: [],
    statements,
    actions,
  };

  return <SettingsContext.Provider value={settings}>{children}</SettingsContext.Provider>;
};

export const useSettingsContext = () => {
  const settingsContext = useContext(SettingsContext);

  if (!settingsContext) {
    throw new Error('No SettingsContext found, make sure to provide one');
  }

  return settingsContext;
};

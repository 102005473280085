module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"wiemagbeginnen","accessToken":"MC5YLUpsVWhBQUFDSUFuYlZ5.77-9Vu-_ve-_vRQZ77-9ae-_ve-_ve-_vQLvv71E77-977-9GO-_vWTvv73vv71Y77-977-9Ek0L77-977-9C09F","releaseID":"example-eiyaingiefahyi7z","schemas":{"player":{"Main":{"name":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"name"}},"uid":{"type":"UID","config":{"label":"id"}},"active":{"type":"Boolean","config":{"default_value":true,"label":"active"}}}},"statement":{"Main":{"uid":{"type":"UID","config":{"label":"id"}},"statement":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"Statement"}},"active":{"type":"Boolean","config":{"default_value":true,"label":"Active"}}}},"action":{"Main":{"uid":{"type":"UID","config":{"label":"id"}},"action":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"Action"}},"active":{"type":"Boolean","config":{"default_value":true,"label":"Active"}}}}},"lang":"*","prismicToolbar":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wie Mag Beginnen","short_name":"Wie Mag Beginnen","start_url":"/","background_color":"#128de0","theme_color":"#128de0","display":"standalone","icon":"src/images/icon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"33e51d3e2127c0aedb41ece9cb6f1800"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
